
let phoenixMapEvent;
let map;
let all_overlays = [];
let selectedShape;
let infoWindow;
let geocoder;
let markers = [];

function getCoordinates(shape) {
	return shape
		.getPath()
		.getArray()
		.map((cord) => {
			return {
				lat: cord.lat(),
				lng: cord.lng(),
			};
		});
}
function clearSelection() {
	if (selectedShape) {
		selectedShape.setEditable(false);
		selectedShape = null;
	}
}
function setSelection(shape) {
	clearSelection();
	selectedShape = shape;
	//temporary removed
  //shape.setEditable(true);
}
function deleteSelectedShape() {
	if (selectedShape) {
		phoenixMapEvent.pushEvent('open-delete-shape-modal', {
			coordinates: getCoordinates(selectedShape),
			id: selectedShape.id,
			name: selectedShape.name,
		});
		// if (selectedShape.name === selectedShape.current_geofence) {
		// 	selectedShape.setMap(null);
		// }
	}
}

function parseShapeCords(shape) {
	return shape.map((cord) => {
		return {
			lat: parseFloat(cord.lat),
			lng: parseFloat(cord.lng),
		};
	});
}

function showInfo(event, newShape) {
	// Since this polygon has only one path, we can call getPath() to return the
	// MVCArray of LatLngs.
	// @ts-ignore
	shape_name = newShape?.name?.toUpperCase() ?? '';
	const polygon = newShape;

	const vertices = polygon.getPath();
	let contentString = `<b style="font-weight: 700;">Geofence Template Name : ${shape_name}</b><br><br> <b style="font-weight: 500;"> Clicked location:</b> <br>
    <b>Lat: ${event.latLng.lat()} </b> <br>
    <b>Long:  ${event.latLng.lng()}</b> <br>`;
	// Iterate over the vertices.
	for (let i = 0; i < vertices.getLength(); i++) {
		const xy = vertices.getAt(i);
		contentString += `<br><b style="font-weight: 500;">Coordinate ${i + 1}:</b>
    <br>
    <b>${xy.lat()}, ${xy.lng()}</b>`;
	}
	// Replace the info window's content and position.
	infoWindow.setContent(contentString);
	infoWindow.setPosition(event.latLng);
	infoWindow.open(map);
}

async function geofenceInitMap(positions, zoom, shapes, relay) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
  const { Autocomplete} = await google.maps.importLibrary('places');
	const { DrawingManager } = await google.maps.importLibrary('drawing');
  geocoder = new google.maps.Geocoder();

	infoWindow = new google.maps.InfoWindow();
	let drawingManager = new google.maps.drawing.DrawingManager({
		drawingMode: google.maps.drawing.OverlayType.null,
		drawingControl: false,
		drawingControlOptions: {
			position: google.maps.ControlPosition.TOP_CENTER,
			drawingModes: ['polygon'],
		},
		polygonOptions: {
			fillColor: '#ffff00',
			strokeWeight: 1,
			editable: false,
		},
	});

	map = new google.maps.Map(document.getElementById('map'), {
		center: new google.maps.LatLng(50.7, 10.28583612505606),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 6,
		streetViewControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
	});

  const input = document.getElementById('autocomplete-input');
  const searchButton = document.querySelector('button[type="submit"]');
  const autocomplete = new google.maps.places.Autocomplete(input);

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();

    if (!place.geometry) {
      // User entered a place that was not suggested
      return;
    }

    // Adjust the map's viewport
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17); // Default zoom level
    }

    // Clear out the old markers
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];

    // Create a marker for the new place
    const marker = new google.maps.Marker({
      map: map,
      position: place.geometry.location,
      title: place.formatted_address || place.name,
    });
    markers.push(marker);

    // Optionally, push event to LiveView if needed
    // relay.pushEvent('location_selected', {
    //   lat: place.geometry.location.lat(),
    //   lng: place.geometry.location.lng(),
    //   address: place.formatted_address,
    // });
  });

  const performSearch = (query) => {
    if (!query) return;

    geocoder.geocode({ address: query }, (results, status) => {
      if (status === 'OK') {
        markers.forEach(marker => marker.setMap(null));
        markers = [];

        const location = results[0].geometry.location;
        map.setCenter(location);
        map.setZoom(14);

        const marker = new google.maps.Marker({
          map,
          position: location,
          title: results[0].formatted_address,
        });
        markers.push(marker);

        // Optionally, push event to LiveView if needed
        // relay.pushEvent('location_selected', {
        //   lat: location.lat(),
        //   lng: location.lng(),
        //   address: results[0].formatted_address,
        // });
      } else {
        console.log(`Geocode was not successful for the following reason: ${status}`);
      }
    });
  };

  input.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      performSearch(input.value);
    }
  });

  searchButton.addEventListener('click', (e) => {
    e.preventDefault();
    performSearch(input.value);
  });

	drawingManager.setMap(map);
  let latlngbounds = new google.maps.LatLngBounds();

	shapes.forEach((shape) => {
    for (let i = 0; i < shape.length; i++) {
			const latlng = new google.maps.LatLng(shape[i].lat, shape[i].lng);
			// Extend the cumulative bounds with each point of the shape
			latlngbounds.extend(latlng);
		}

		let newShape = new google.maps.Polygon({
			paths: parseShapeCords(shape),
			strokeColor: '#000',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor:
				shape[0].name === shape[0].current_geofence ? '#ffff00' : '#009fff',
			fillOpacity: 0.35,
			editable: false,
		});
		shape_names = shape[0].name;
		shapes_id = shape[0].id;
		current_geofence = shape[0].current_geofence;
		all_overlays.push(newShape);
		newShape.name = shape_names;
		newShape.current_geofence = current_geofence;
		newShape.id = shapes_id;
		newShape.setMap(map);
		google.maps.event.addListener(newShape, 'click', function () {
			setSelection(newShape);
		});
		newShape.addListener('click', (e) => showInfo(e, newShape));
	});
  // Fit the map to the cumulative bounds
  if (shapes.length > 0) {
    map.fitBounds(latlngbounds);
  }

	google.maps.event.addListener(
		drawingManager,
		'overlaycomplete',
		function (e) {
			all_overlays.push(e);
			if (e.type != google.maps.drawing.OverlayType.MARKER) {
				// Switch back to non-drawing mode after drawing a shape.
				drawingManager.setDrawingMode(null);
				// Add an event listener that selects the newly-drawn shape when the user
				// mouses down on it.
				var newShape = e.overlay;
				newShape.type = e.type;
				google.maps.event.addListener(newShape, 'click', function () {
					setSelection(newShape);
				});
				newShape.addListener('click', (e) => showInfo(e, newShape));
				setSelection(newShape);
			}
			let arrayOfLatLng = [];
			newShape
				.getPath()
				.getArray()
				.forEach((element) => {
					arrayOfLatLng.push({
						latitude: element.lat(),
						longitude: element.lng(),
					});
				});
			relay.pushEvent('open-new-shape-modal', { arrayOfLatLng });
		}
	);
	// get shapes lat lang when user edit a shape
	google.maps.event.addListener(
		drawingManager,
		'overlaycomplete',
		function (event) {
			if (event.type == google.maps.drawing.OverlayType.POLYGON) {
				google.maps.event.addListener(event.overlay.getPath(), 'set_at', () => {
					let arrayOfPath = event.overlay.getPath().getArray();
					let arrayOfLatLng = [];
					arrayOfPath.forEach((element) => {
						arrayOfLatLng.push({
							latitude: element.lat(),
							longitude: element.lng(),
						});
					});
				});
			}
		}
	);

  const deleteButton = document.getElementById('delete-button');

  if (deleteButton) {
    deleteButton.addEventListener('click', deleteSelectedShape);
  }
  
	google.maps.event.addListener(
		drawingManager,
		'drawingmode_changed',
		clearSelection
	);
	google.maps.event.addListener(map, 'click', clearSelection);

	document.getElementById('polygonTool').addEventListener('click', function () {
		drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
	});
	document.getElementById('selectTool').addEventListener('click', function () {
		drawingManager.setDrawingMode(null);
	});

}

const GeofenceGooglemapsHook = {
	mounted() {
		let relay = this;
		phoenixMapEvent = this;
    this.observePacContainer();

		const handleNewMarker = ({ positions: positions, zoom, shapes, id }) => {
			geofenceInitMap(positions, zoom, shapes, relay);
		};

		const deleteLastOverlay = () => {
			all_overlays.pop().overlay.setMap(null);
		};
		const addNameToShape = ({ name }) => {
			all_overlays[all_overlays.length - 1].overlay.name = name;
		};

		const handleRemoveShape = ({ id }) => {
			const shapeToRemove = all_overlays.find((shape) => shape.id === id);
			if (shapeToRemove) {
				shapeToRemove.setMap(null);
				infoWindow.close();
			}
		};

    const handleSearchResults = ({search_term}) => {
      //console.log('GmapsSearchHook handleSearchResults', search_term);
      const query = search_term;

      if (!query) {
        //console.log("No search query provided.");
        return;
      }

      geocoder.geocode({ address: query }, (results, status) => {
        if (status === 'OK') {
          markers.forEach(marker => marker.setMap(null));
          markers = [];
  
          const location = results[0].geometry.location;
          map.setCenter(location);
          map.setZoom(14);
  
          const marker = new google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });
          markers.push(marker);

          //console.log(`Found location: ${results[0].formatted_address}`);
        } else {
          //console.log(`Geocode was not successful for the following reason: ${status}`);
        }
      });
     
    };

    //this.handleEvent('places_changed', handleSearchResults);

		this.handleEvent('marker', handleNewMarker);
		this.handleEvent('deleteLastOverlay', deleteLastOverlay);
		this.handleEvent('addNameToShape', addNameToShape);
		this.handleEvent('deleteShapeInUi', handleRemoveShape);

    const selectToolButton = document.getElementById('selectTool');
    const polygonToolButton = document.getElementById('polygonTool');
    function setActiveButton(button) {
      if (polygonToolButton) {
        polygonToolButton.classList.remove('btn-active');
      }
      selectToolButton.classList.remove('btn-active');
      
      button.classList.add('btn-active');
    }

    setActiveButton(selectToolButton);

    selectToolButton.addEventListener('click', function () {
      setActiveButton(selectToolButton);
      drawingManager.setDrawingMode(null);
    });

    if (polygonToolButton) {
      polygonToolButton.addEventListener('click', function () {
        setActiveButton(polygonToolButton);
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      });
    }

    relay.handleEvent("geofence_drawn_confirmed", () => {
      setActiveButton(selectToolButton);
    });

	},
  // observe googlemaps autocomplete dropdown and change input class based on that
  observePacContainer() {
    const input = document.getElementById('autocomplete-input');
    let pacObserver;
  
    const checkPacContainerVisibility = (pacContainer) => {
      const isVisible =
        pacContainer.offsetParent !== null &&
        pacContainer.style.display !== 'none' &&
        pacContainer.style.visibility !== 'hidden';
  
      if (isVisible) {
        input.classList.add('dropdown-open');
      } else {
        input.classList.remove('dropdown-open');
      }
    };
  
    const startObservingPacContainer = () => {
      const pacContainer = document.querySelector('.pac-container');
      if (pacContainer) {
        // Observe changes in the pac-container's attributes
        pacObserver = new MutationObserver(() => {
          checkPacContainerVisibility(pacContainer);
        });
  
        pacObserver.observe(pacContainer, { attributes: true, childList: true, subtree: true });
  
        // Initial check
        checkPacContainerVisibility(pacContainer);
      }
    };
  
    // Observe the addition of the pac-container to the DOM
    const bodyObserver = new MutationObserver(() => {
      startObservingPacContainer();
    });
  
    bodyObserver.observe(document.body, { childList: true, subtree: true });
  
    // Start observing immediately in case the pac-container is already present
    startObservingPacContainer();
  
    // Clean up observers when the component is destroyed
    this.handleEvent('phx:remove', () => {
      if (pacObserver) pacObserver.disconnect();
      bodyObserver.disconnect();
    });
  }

};

export default GeofenceGooglemapsHook;

