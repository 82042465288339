const options = {
	multiselect: {
		allowHTML: true,
		placeholderValue: 'Select...',
		searchPlaceholderValue: 'Search',
		removeItemButton: true,
	},
	multitag: {
		allowHTML: true,
		placeholderValue: 'Digit new value...',
		searchPlaceholderValue: 'Search',
		removeItemButton: true,
	},
	singleselect: {
		allowHTML: true,
		placeholder: true,
		placeholderValue: 'Select...',
		searchPlaceholderValue: 'Select...',
		removeItemButton: false,
		searchEnabled: true,
		shouldSort: false,
	},
};

const ChoicesHook = {
	mounted() {
		initial_options = options[this.el.dataset.type];
		id = this.el.dataset.id;
		choices = new Choices(this.el, initial_options);

		this.handleEvent('update_choices_' + id, (data) => {
			initial_options.choices = data.choices;
			console.log('initial_options: ', initial_options);

			choices.destroy();
			choices = new Choices(this.el, initial_options);
		});
	},
	updated() {
		choices.destroy();
		choices = new Choices(this.el, options[this.el.dataset.type]);
	},
};

export default ChoicesHook;
